import { navigate } from '@reach/router';
import Footer from 'components/CommonComponents/Footer';
import CareerForm from 'components/CommonComponents/Forms/CareerForm';
import NavigationBar from 'components/CommonComponents/NavigationBar';
import SEO from 'components/CommonComponents/SEO';
import { AccentMainTitleText, TitleText } from 'components/CommonComponents/Titles/styled';
import { SectionWithBackground, StyledSection } from 'components/CommonComponents/Wrappers/styled';
import {
  CareerCarrierDroneGreen,
  CareerCarrierDronePurple,
  CareerIntroHeader,
  CareerIntroMainWrapper,
  CareerIntroText,
  CareerIntroWrapper,
  CareerPhoneBlue,
  ImInterested,
  MainContainer,
  NotExactMatchHeader,
  NotExactMatchText,
  NotExactMatchWrapper,
  OfferHeader,
  OfferImageWrapper,
  OffersWrapper,
  OfferTechImage,
  OfferTechImagesWrapper,
  OfferText,
  OfferWrapper,
  PositionHeader,
  PositionInnerWrapper,
  PositionsWrapper,
  PositionText,
  Separator,
} from 'components/PageCareer/styled';
import { GlobalStyle } from 'constants/globalTheme';
import { graphql, useStaticQuery } from 'gatsby';
import React, { Fragment, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

const pageQuery = graphql`
  {
    careerYaml {
      mainHeading {
        cs
      }
      careerIntroTechImages {
        publicURL
      }
      careerIntro {
        backgroundImage {
          publicURL
        }
        heading {
          cs
        }
        text {
          cs
        }
      }
      importantToUs {
        header {
          cs
        }
      }
      weAreLookingFor {
        header {
          cs
        }
        imInterested {
          cs
        }
      }
      notExactMatch {
        header {
          cs
        }
        text {
          cs
        }
      }
    }
    allImportanttouslistYaml {
      nodes {
        header {
          cs
        }
        text {
          cs
        }
        backgroundImage {
          publicURL
        }
      }
    }
    allPositionsYaml {
      nodes {
        header {
          cs
        }
        sneak {
          cs
        }
        slug
      }
    }
    messageusYaml {
      careerFormImage {
        publicURL
      }
    }
  }
`;

const Career = () => {
  const locale = 'cs';
  const data = useStaticQuery(pageQuery);

  const careerData = data.careerYaml;
  const importantToUs = data.allImportanttouslistYaml.nodes;
  const positions = data.allPositionsYaml.nodes;

  const [focusPosition, setFocusPosition] = useState<number | null>(null);

  return (
    <>
      <SEO title="Kariéra" />
      <GlobalStyle />

      <NavigationBar />
      <MainContainer>
        <StyledSection>
          <AccentMainTitleText>{careerData.mainHeading[locale]}</AccentMainTitleText>
          <CareerIntroMainWrapper>
            {careerData.careerIntro.map((item: any, index: number) => (
              <Fragment key={index}>
                {index === 0 && <CareerCarrierDronePurple src={item.backgroundImage.publicURL} />}
                {index === 1 && <CareerPhoneBlue src={item.backgroundImage.publicURL} />}
                {index === 2 && <CareerCarrierDroneGreen src={item.backgroundImage.publicURL} />}
                <CareerIntroWrapper offset={index > 3 ? 3 : index}>
                  <CareerIntroHeader>{item.heading[locale]}</CareerIntroHeader>
                  <CareerIntroText>{item.text[locale]}</CareerIntroText>
                </CareerIntroWrapper>
              </Fragment>
            ))}
          </CareerIntroMainWrapper>
          <Separator />
        </StyledSection>
        <StyledSection>
          <TitleText>{careerData.importantToUs.header[locale]}</TitleText>
          <Separator />
          <OffersWrapper>
            {importantToUs.map((item: any, index: number) => (
              <OfferWrapper key={index}>
                <OfferImageWrapper>
                  <img src={item.backgroundImage.publicURL} />
                </OfferImageWrapper>
                <OfferHeader>{item.header[locale]}</OfferHeader>
                <OfferText>{ReactHtmlParser(item.text[locale])}</OfferText>
                {index === 3 && (
                  <OfferTechImagesWrapper>
                    {careerData.careerIntroTechImages.map((image: any, techIndex: number) => (
                      <OfferTechImage src={image.publicURL} key={techIndex} />
                    ))}
                  </OfferTechImagesWrapper>
                )}
              </OfferWrapper>
            ))}
          </OffersWrapper>
          <Separator />
        </StyledSection>
        <StyledSection>
          <TitleText>{careerData.weAreLookingFor.header[locale]}</TitleText>
          <Separator />
          <PositionsWrapper>
            {positions.map((position: any, index: number) => {
              return (
                <PositionInnerWrapper
                  key={index}
                  isFocused={focusPosition === index}
                  onMouseEnter={() => setFocusPosition(index)}
                  onMouseLeave={() => setFocusPosition(null)}
                  style={{ cursor: focusPosition === index ? 'pointer' : 'inherit' }}
                  onClick={() => (focusPosition === index ? navigate(`/job/${position.slug}`) : setFocusPosition(index))}
                >
                  {focusPosition === index ? (
                    <>
                      <ImInterested>
                        <span>{careerData.weAreLookingFor.imInterested[locale]}</span>
                      </ImInterested>
                    </>
                  ) : (
                    <>
                      <PositionHeader>{position.header[locale]}</PositionHeader>
                      <PositionText>{position.sneak[locale]}</PositionText>
                    </>
                  )}
                </PositionInnerWrapper>
              );
            })}
          </PositionsWrapper>
        </StyledSection>
        <SectionWithBackground imageLink={data.messageusYaml.careerFormImage.publicURL}>
          <StyledSection as="div">
            <NotExactMatchWrapper>
              <NotExactMatchHeader>{careerData.notExactMatch.header[locale]}</NotExactMatchHeader>
              <NotExactMatchText>{careerData.notExactMatch.text[locale]}</NotExactMatchText>
              <CareerForm />
            </NotExactMatchWrapper>
          </StyledSection>
        </SectionWithBackground>
      </MainContainer>
      <Footer />
    </>
  );
};

export default Career;
